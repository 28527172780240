import { Redirect, Route, Switch } from "react-router";
import React, { ReactNode } from "react";
import HomePage from "../containers/HomePage";
import Login from "../containers/Login";
import Profile from "../privateContainers/profile";
import AddNews from "../privateContainers/addNews";
import AddEvent from "../privateContainers/addEvent";


const Routes = () => {
    return(
        <Switch>
            <Route path="/" exact>
                <HomePage/>
            </Route>
            <Route path="/login" exact>
                <Login/>
            </Route>
            <PrivateRoute path="/profile">
                <Profile />
            </PrivateRoute>
            <PrivateRoute path="/addnews">
                <AddNews />
            </PrivateRoute>
            <PrivateRoute path="/addevent">
                <AddEvent />
            </PrivateRoute>
        </Switch>
    )
}

interface PrivateRouteProps {
    children: ReactNode;
    path: string;
}


function PrivateRoute({ children , ...rest }: PrivateRouteProps) {
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString ?? '{}');
  const token = user?.token;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default Routes;