import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { ADD_LATEST_NEWS } from '../../graphql/types';
import './AddLatestNews.scss';

interface profileData {
    id: number;
    email: string;
    user: string;
    token: string;
}

type Props = {
    profile: profileData
}

const AddLatestNews = ({profile}: Props) => {
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [image, setImage] = useState('');
    const [file, setFile] = useState<File>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [ADD_LATEST_NEWS_MUTATION] = useMutation(ADD_LATEST_NEWS, {
        variables: {
            title,
            description,
            url,
            image
        }
    });

    const handleImage = (event: any) => {
        setFile(event.target.files[0]);
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function () {
            if (typeof reader.result === 'string') {
                setImage(reader.result);
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const Submit = () => {
        ADD_LATEST_NEWS_MUTATION()
        .then( ({data})=>{
            if (data && data.addToLatestNews){
                setTitle('');
                setDescription('');
                setFile(undefined);
                setImage('');
                setUrl('');
            }
        })
        .catch(()=> {
            setError("invalid email or password, please try again.");
        })
    }

    return (
        <div className="add-latest-news-container">
            <h1 className='latest-news-header'>Add to Latest News</h1>
            <div className='latest-news-section'>
                <label>Title</label>
                <input value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div className='latest-news-section'>
                <label>Description</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className='latest-news-section'>
                <label>Url</label>
                <input value={url} onChange={(e) => setUrl(e.target.value)} />
            </div>
            <div className='latest-news-section'>
                <label>Image</label>
                <input ref={inputRef} type="file" onChange={(event) => handleImage(event)} />
            </div>
            <button onClick={Submit}>Submit</button>
            {error && <span>{error}</span>}
        </div>
    )
}

export default AddLatestNews;