import React, { useEffect, useState } from "react";
import { getLocalUser } from "../../utils/helper";
import "./Profile.scss";

const Profile = () => {
    const [profile, setProfile] = useState<profileData>();
    interface profileData {
        id: number;
        email: string;
        user: string;
        token: string;
    }

    useEffect(() => {
        setProfile(getLocalUser())
    }, []);

    if (!profile) {
        return <div/>
    }
    

    return (
        <div className="profile-container">
            <h1>Profile</h1>
            <h4 className="profile-detail">id: {profile?.id}</h4>
            <h4 className="profile-detail">email: {profile?.email}</h4>
            <h4 className="profile-detail">token: {profile?.token}</h4>
            <h4 className="profile-detail">user: {profile?.user}</h4>
        </div>
      );
}

export default Profile;