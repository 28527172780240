import React, { useEffect, useState } from "react";
import './Footer.scss';
import { useHistory } from "react-router-dom";
import FooterLinkSection from "../footerLinkSection/FooterLinkSection";
import { copyrightLinks} from "./footerVariables"; 

import facebookLogo from "../../images/footerLogos/social/facebook.png";
import twitterLogo from "../../images/footerLogos/social/twitter.png";
import instagramLogo from "../../images/footerLogos/social/instagram.png";
import youtubeLogo from "../../images/footerLogos/social/youtube.png";
import linkedinLogo from "../../images/footerLogos/social/linkedin.png";

const Footer = () => {
    const history = useHistory();
    const [isHome,setIsHome] = useState<boolean> (false);

    useEffect(() => {
        setIsHome (history.location.pathname === '/1');
        return history.listen(() => { 
            setIsHome (history.location.pathname === '/1');
        })
        
    },[history])
    return (
        <div className="generalContainer1 footerContainer1" >
            <div className="generalContainer2 footerContainer2">
                {isHome && <div className="mainFooterSectionContainer mainFooterNewsletterTop">
                    <div className="footerNewsletter"> 
                        <form className="footerNewsletterForm">
                            <h4>Newsletter Form</h4>
                            <input className="footerNewsletterInput" type="text" placeholder="Name*"/>
                            <input className="footerNewsletterInput" type="email" placeholder="Email*"/>
                            <input className="footerNewsletterSubmit" type="submit"/>
                        
                        </form>
                    </div>
                    <div className="footerSocial">
                        <a className="footerSocialLogo" href="#1"> <img className="fbLogo" src={facebookLogo} alt="FaceBook"/> </a>
                        <a className="footerSocialLogo" href="#2"> <img src={twitterLogo} alt="FaceBook"/> </a>
                        <a className="footerSocialLogo" href="#3"> <img src={instagramLogo} alt="FaceBook"/> </a>
                        <a className="footerSocialLogo" href="#4"> <img src={youtubeLogo} alt="FaceBook"/> </a>
                        <a className="footerSocialLogo" href="#5"> <img src={linkedinLogo} alt="FaceBook"/> </a>
                    </div>
                </div>}
                <div className="mainFooterSectionContainer copyrightSection">
                    <div className="footerBottomSection"> 
                        <FooterLinkSection className={'footerBottomSectionUrls'} links = {copyrightLinks}/>
                    </div>
                    <div className="footerBottomSection">
                        <span className="copyrightText">Copyright&copy; 2020. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;