import React, { useEffect, useState } from "react";
import AddLatestNews from "../../components/addLatestNews/AddLatestNews";
import { getLocalUser } from "../../utils/helper";
import "./AddNews.scss";

const AddNews = () => {
    const [profile, setProfile] = useState<profileData>();
    interface profileData {
        id: number;
        email: string;
        user: string;
        token: string;
    }

    useEffect(() => {
        setProfile(getLocalUser())
    }, []);

    if (!profile) {
        return <div/>
    }
    

    return (
        <div className="profile-container">
            <h1>Profile</h1>
            <AddLatestNews profile={profile} />
          </div>
      );
}

export default AddNews;