import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { ADD_EVENT } from '../../graphql/types';
import './AddEvent.scss';

interface profileData {
    id: number;
    email: string;
    user: string;
    token: string;
}

type Props = {
    profile: profileData
}

const AddEvent = ({profile}: Props) => {
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [time, setTime] = useState('');
    const [url, setUrl] = useState('');
    const [photo, setPhoto] = useState('');
    const [location, setLocation] = useState('');
    const [file, setFile] = useState<File>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [ADD_EVENT_MUTATION] = useMutation(ADD_EVENT, {
        variables: {
            name,
            description,
            url,
            photo,
            time,
            location
        }
    });

    const handleImage = (event: any) => {
        setFile(event.target.files[0]);
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function () {
            if (typeof reader.result === 'string') {
                setPhoto(reader.result);
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const Submit = () => {
        ADD_EVENT_MUTATION()
        .then( ({data})=>{
            if (data && data.addEvent){
                setName('');
                setDescription('');
                setFile(undefined);
                setPhoto('');
                setUrl('');
                setTime('');
            }
        })
        .catch(()=> {
            setError("invalid email or password, please try again.");
        })
    }

    return (
        <div className="add-latest-news-container">
            <h1 className='latest-news-header'>Add Event</h1>
            <div className='latest-news-section'>
                <label>Event Name</label>
                <input value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='latest-news-section'>
                <label>Description</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className='latest-news-section'>
                <label>Event Time</label>
                <input value={time} onChange={(e) => setTime(e.target.value)} />
            </div>
            <div className='latest-news-section'>
                <label>Url</label>
                <input value={url} onChange={(e) => setUrl(e.target.value)} />
            </div>
            <div className='latest-news-section'>
                <label>Image</label>
                <input ref={inputRef} type="file" onChange={(event) => handleImage(event)} />
            </div>
            <div className='latest-news-section'>
                <label>Location</label>
                <input value={location} onChange={(e) => setLocation(e.target.value)} />
            </div>
            <button onClick={Submit}>Submit</button>
            {error && <span>{error}</span>}
        </div>
    )
}

export default AddEvent;