import React, { FC, ReactElement, ReactNode } from 'react';
import './Input.scss';

type Props = {
    text?: string,
    disable?: boolean,
    className?: string,
    onChange: Function,
    children?: ReactNode,
    id: string,
    type?: string
};

const Input: FC<Props> = ( { id, text, type, className, onChange } ) : ReactElement  => {
    return (
        <input type={type ?? "text"} id={id} value={text} onChange={(e)=>onChange(e.target.value)} className={className} />
    )
}

export default Input;