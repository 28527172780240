import { InMemoryCache } from '@apollo/client';

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Tournament: {
      fields: {
        rounds: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
        },
      },
    },
  },
});