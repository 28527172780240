import React, { useEffect, useState } from "react";
import AddEventComponent from "../../components/addEvent/AddEvent";
import { getLocalUser } from "../../utils/helper";
import "./AddEvent.scss";

const AddEvent = () => {
    const [profile, setProfile] = useState<profileData>();
    interface profileData {
        id: number;
        email: string;
        user: string;
        token: string;
    }

    useEffect(() => {
        setProfile(getLocalUser())
    }, []);

    if (!profile) {
        return <div/>
    }
    

    return (
        <div className="profile-container">
            <h1>Profile</h1>
            <AddEventComponent profile={profile} />
          </div>
      );
}

export default AddEvent;