import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
	mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
            id,
            user,
            email,
            token
        }
	}
`;

export const LOGOUT_MUTATION = gql`
	mutation logout {
		logout {
			id
		}
	}
`;

export const ADD_LATEST_NEWS = gql`
	mutation addToLatestNews($title: String!, $description: String!, $url: String!, $image: String!) {
		addToLatestNews(title: $title, description: $description, url: $url, image: $image)
	}
`;

export const ADD_EVENT = gql`
	mutation addEvent(
        $name: String!,
        $description: String!,
        $url: String!,
        $time: String!,
        $photo: String!,
        $location: String!
    ) {
		addEvent(
            eventDetail: {
                name: $name,
                time: $time,
                description: $description,
                url: $url,
                photo: $photo,
                location: $location
            }
        )
	}
`;

export const STATUS = gql`
    subscription status {
        status {
            title
        }
    }
`